// client side helper for admin report
export async function adminReportEmail(reportDetails) {
    try {
        const response = await fetch('/api/admin/admin-email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(reportDetails),
        });
        const result = await response.json();

        if (!response.ok) {
            console.error('Error sending report to admin route:', result);
        } 
        
    } catch (error) {
        console.error('adminReportEmail', 'Error sending report to admin route', error);
    }
}

export async function logClient(functionName, logMessage, error, includeStack = false) {
    try {
        const logData = {
            functionName: functionName,
            logMessage: logMessage || 'No log message available',
            errorCode: error.errorCode || 'No error code available',
            message: error.message || 'No error message available',
        };

        if (error && error.response) {
            const response = {};

            if (error.response.data && error.response.data !== 'No response data available') {
            response.data = error.response.data;
            }
            if (error.response.status) {
            response.status = error.response.status;
            }
            if (error.response.headers) {
            response.headers = error.response.headers;
            }
            if (Object.keys(response).length > 0) {
            logData.response = response; 
            } 
        };

        if (includeStack && error.stack) {
            logData.stack = error.stack;
        }

        const response = await fetch('/api/admin/log-client-error', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(logData),
        });

        const data = await response.json();
        
    } catch (err) {
        console.error('Failed to log error:', err);
    }
}



