import {logClient} from '../helpers/adminHelper.js';

const Isotope = require('isotope-layout');
const imagesLoaded = require('imagesloaded');

    // Initialize Isotope and imagesLoaded changing something
    const elem = document.querySelector('.gallery-grid');

    if (elem) {
        const iso = new Isotope(elem, {
            itemSelector: '.gallery-img',
            layoutMode: 'masonry',
            percentPosition: true,
            masonry: {
                columnWidth: '.gallery-img',
            }
        });

        imagesLoaded(elem).on('done', () => {
            iso.layout();
        });
    } else {
        logClient('gridView.js','Isotope grid container not found.', 'warn');
    }